import {
  signalStore,
  withState,
  withComputed,
  withMethods,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { ChannelService, ToasterService } from '@desquare/services';
import { Signal, computed, inject, signal } from '@angular/core';
import { distinctUntilChanged, pipe, switchMap } from 'rxjs';
import { GraphQLFormattedError } from 'graphql';
import { Channel, ChannelPlaylist } from '@designage/gql';
import { IPlaylistRegion } from '@desquare/interfaces';
import { ApolloError } from '@apollo/client';

export interface IChannelSignal {
  channels: Channel[];
  loading: boolean;
  errors: GraphQLFormattedError<Record<string, any>>[] | null;
}

const initialState: IChannelSignal = {
  channels: [],
  loading: true,
  errors: null,
};

export const ChannelsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withMethods(
    (
      store,
      channelService = inject(ChannelService),
      toasterService = inject(ToasterService)
    ) => ({
      getChannelsFromApi: rxMethod<void>(
        pipe(
          distinctUntilChanged(),
          switchMap(() => {
            return channelService.getChannelsFromApi$().pipe(
              tapResponse({
                next: ({ channels, errors, loading }) => {
                  patchState(store, {
                    channels,
                    loading,
                    errors: errors ? [...errors] : [],
                  });
                },
                error: console.error,
                finalize: () => patchState(store, { loading: false }),
              })
            );
          })
        )
      ),
      getChannel: (channelId: string): Signal<Channel | undefined> => {
        const channel = signal<Channel | undefined>(
          store.channels().find((x) => x.id === channelId)
        );
        return channel;
      },
      getChannelsById: (channelIds: string[]): Signal<Channel[]> => {
        const channels = signal<Channel[]>([]);
        channels.update(() =>
          channelIds.reduce((state, ids) => {
            const foundDevice = store.channels().find((x) => x.id === ids);
            return foundDevice ? [...state, foundDevice] : state;
          }, [] as Channel[])
        );
        return channels;
      },
      updateChannel: (channel: Channel) => {
        patchState(store, {
          channels: store.channels().map((x) => {
            if (x.id === channel.id) {
              return { ...x, ...channel };
            } else {
              return x;
            }
          }),
        });
      },
      addChannel: (channel: Channel) => {
        patchState(store, {
          channels: [...store.channels(), channel],
        });
      },
      removeChannel: (channelId: string) => {
        patchState(store, {
          channels: store.channels().filter((x) => x.id !== channelId),
        });
      },
      updateRegion: (channelId: string, input: IPlaylistRegion) => {
        const { playlistId, region } = input;
        channelService
          .updateRegionGql(channelId, { playlistId, region })
          .then(({ data }) => {
            const updatedChannel = data?.assignChannelPlaylistRegion;
            if (updatedChannel?.isSuccessful) {
              toasterService.success('REGION_UPDATE_SUCCESS');
              return patchState(store, {
                channels: store.channels().map((channel) => {
                  if (channel.id === channelId) {
                    channel.playlistRegions?.map((x) => {
                      if (x.playlistId === playlistId) {
                        x.region = region;
                      }
                      return x;
                    });
                  }
                  return { ...channel };
                }),
              });
            } else {
              toasterService.error('REGION_UPDATE_ERROR');
              return null;
            }
          })
          .catch((error: ApolloError) => {
            error.graphQLErrors.forEach((gqlError) => {
              console.error('updateRegion', gqlError);
              inject(ToasterService).handleGqlError(gqlError);
            });
          });
      },
    })
  ),
  withHooks((store) => ({
    onInit() {
      // Load products when the store is initialized
      store.getChannelsFromApi();
    },
  })),
  withComputed((state) => ({}))
);
